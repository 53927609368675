import $ from 'jquery';

import Utils from '../global/Utils';
import Api from '../global/Api';

let current = '';

export default class TopBar  {
  constructor(o) {
    this.obj = $(o);
    this.el = $(o).get();
    this.addListeners();
  }

  addListeners() {
    let modalObj = $('.modal.region-selector');
    let navigationObj = this.obj.find('.navigation');

    modalObj.find('.icon-cross').on('click', function() {
      modalObj.removeClass('show');
      $('body').removeClass('noscroll');
      return false;
    });

    this.obj.find('.lang-selector').on('click', function() {
      modalObj.addClass('show');
      navigationObj.removeClass('show');
      $('body').addClass('noscroll');
      $.ajax({
        dataType: 'json',
        url: Api.getSites(),
        success: function(data) {
          if (data) {
            var lis = '';

            data.forEach(function(obj) {
              if (!obj.active) {
                    lis += '<li><a href="'+obj.url+'">'+obj.displayName+'</a></li>';
                    globalUrl = obj.url;
                }
            });
            lis += '<li><a href="../">Global</a></li>';
            $('.region-selector').find('ul').html(lis);
          }
        }
      });
      // Prevent scroll behind modals
      return false;
    });

    navigationObj.find('.icon-cross').on('click', function() {
      navigationObj.removeClass('show');
      $('body').removeClass('noscroll');
      return false;
    });

		this.obj.find('.fas.fa-bars').on('click', function() {
      navigationObj.addClass('show');

      $('body').addClass('noscroll');
      return false;
    });
    /*this.obj.find('li').on('click', e => {
      window.location.href = '#' + $(e.currentTarget).attr('data-product');
    });*/

  }

}
