import $ from 'jquery';
import fitvid from 'fitvids';
import { TweenMax, Linear } from 'gsap';

export default class RecipeIngredients	{
	constructor(o) {
		this.obj = $(o);
		this.el = $(o).get();
		this.addListeners();
		// console.log('Hooking: RecipeIngredients to ', this.obj);

    var self = this;
    let prepTime, animateObj;
    $('.preptime').each(function() {
      prepTime = $(this).html();
      if (!parseInt(prepTime, 10)) {
        prepTime = 0;
      }
      animateObj = $(this).parents('.clock-wrap');

      self.animatePrepTime(prepTime, animateObj);
    });
	}

	addListeners() {
		this.obj.find('.amount-adjustor a.minus').on('click', e => {
			e.preventDefault();
			this.calculateRecipe(-1);
		});
		this.obj.find('.amount-adjustor a.plus').on('click', e => {
			e.preventDefault();
			this.calculateRecipe(1);
		});

		let modalObj = $('.modal.region-selector');

		modalObj.find('.icon-cross').on('click', function() {
			modalObj.removeClass('show');
			modalObj.find('.content').html('');
			return false;
		});

		this.obj.find('a.video').on('click', function(e) {
			modalObj.find('textarea').val($(e.currentTarget).attr('href'));
			modalObj.addClass('show');
			let string = '<iframe width="560" height="315" src="';
			string += modalObj.find('textarea').val();
			string += '?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>';
			// console.log(string);

			modalObj.find('.content').html(string);

			fitvid('.fitvid');
			return false;
		});
	}

  animatePrepTime(time, target) {
    let degrees,
        degreesSpinOne,
        degreesSpinTwo,
        timerSpinOne,
        timerSpinTwo;

    if (time > 60) {
      degrees = 360;
    } else {
      degrees = time / 60;
      degrees = 360 * degrees;
    }

    if (degrees - 180 > 0) {
      degreesSpinOne = 180;
      degreesSpinTwo = degrees - 180;
    } else {
      degreesSpinOne = degrees;
    }

    timerSpinOne = (degreesSpinOne / degrees) * 1;
    timerSpinTwo = 1 - timerSpinOne;

    //TweenMax.set('.circle-loader .right.side .spinner', { transform: 'rotate(0deg)' });
    TweenMax.to(target.find('.right.side .spinner'), timerSpinOne, { css: { rotation: degreesSpinOne },
      ease: Linear.easeNone,
      onComplete: function() {
        TweenMax.to(target.find('.left.side .spinner'), timerSpinTwo, { css: { rotation: degreesSpinTwo },
          ease: Linear.easeNone
        });
      }
    });
  }

	calculateRecipe(dir) {
		let currentYields = parseInt(this.obj.find('.amount-adjustor .label > .yields').text(), 10);
		let yields = Math.max(currentYields + dir, 1);
		if (currentYields === yields) {
			return false;
		}

		this.obj.find('.amount-adjustor .label > .yields').text(yields);

		let newValues = [];
		this.obj.find('.ingredient-list > li').each((i, o) => {
			if ($(o).find('.amount').attr('data-base-amount') === '0.0') {
				// console.log('ignoring', $(o));
				return;
			}
			newValues.push({
				el: $(o),
				unit: $(o).find('.unit').attr('data-base-unit'),
				amount: $(o).find('.amount').attr('data-base-amount') * yields
			});
		});

		newValues.forEach(o => {
			$(o.el).find('.amount').text(this.formatAmount(o.amount));
		});
	}

	formatAmount(amount) {
		if (!amount || amount === 0) {
			return '';
		}
		let amountString = amount.toString();
		let decimalPos = amountString.indexOf('.');

		if (decimalPos < 0) {
			return amountString;
		}
		let decimals = amountString.split('.');

		if (decimals === '.0') {
			return decimals[0];
		}
		if (decimals[1].indexOf('33') === 0) {
			return this.nz(decimals[0]) + '1/3';
		}
		if (decimals[1].indexOf('66') === 0) {
			return this.nz(decimals[0]) + '2/3';
		}
		if (decimals[1].indexOf('16') === 0) {
			return this.nz(decimals[0]) + '1/6';
		}
		return parseFloat(amount.toFixed(2));
	}

	nz(str) {
		if (parseInt(str, 10) === 0) {
			return '';
		}
		return str + ' ';
	}
}
